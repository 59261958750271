<template>
<v-app class="scroll">

    <div class="app-container app-theme-white">
        <transition name="fade" mode="out-in" appear>
            <Header @logout="logout" />
        </transition>
        <transition name="fade" mode="out-in" appear>
            <div class="app-sidebar sidebar-shadow" @mouseover="toggleSidebarHover('add', 'closed-sidebar-open')" @mouseleave="toggleSidebarHover('remove', 'closed-sidebar-open')">
                <div class="app-header__logo" style="opacity:0.7">
                    <div class="header__pane ml-auto">
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active': isOpen }" @click="toggleBodyClass('closed-sidebar')">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="app-sidebar-content">
                    <VuePerfectScrollbar class="app-sidebar-scroll">
                        <sidebar-menu style="font-family: Calibri;font-size: 14px;" showOneChild :menu="userMenu" />
                    </VuePerfectScrollbar>
                </div>
            </div>

        </transition>

        <div class="app-main__outer">
            <div class="app-main__inner">
                <v-main class="mb-10">
                    <!-- <v-container fluid class="pt-0 mt-0"> -->
                        <page-title :icon="icon" style="height:62px"></page-title>
                        <router-view />
                    <!-- </v-container> -->
                </v-main>
            </div>
            <transition name="fade" mode="out-in">
                <Footer />
            </transition>
        </div>
    </div>
</v-app>
</template>

<script>
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";
import Breadcrumb from "@/components/Layout/Breadcrumb.vue";
import {
    SidebarMenu
} from "vue-sidebar-menu";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

//Services
import _sAuth from "@/services/AuthService";

//Components
import PageTitle from "@/components/Layout/PageTitle.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        Breadcrumb,
        SidebarMenu,
        VuePerfectScrollbar,
        PageTitle
    },
    data() {
        return {
            userMenu: [],
            menu: [],
            isOpen: false,
            sidebarActive: false,
            collapsed: true,
            windowWidth: 0,
            icon: "pe-7s-home icon-gradient bg-amy-crisp",


            timeToken: null,

        };
    },
    created() {
        this.initializeOptions()
        this.configurateActivity()
    },
    methods: {

        initializeOptions() {
            this.userMenu = []
            this.userMenu = JSON.parse(localStorage.getItem('UserOptions')) || [];
            this.userMenu.push({
                optOrder: -1,
                header: true,
                title: "Menu",
            });

            this.userMenu.forEach((opt) => {
                opt.icon = opt.optIcon;

                if (opt.childCount > 0 && opt.child && opt.child.length > 0) {
                    opt.child.forEach((element) => {
                        element.icon = null;
                        element.href = this.$router.options.routes[1].children.find(
                            (x) => x.name == element.optCode
                        );

                        if (element.childCount > 0 && element.child && element.child.length > 0) {
                            element.child.forEach((element2) => {
                                element2.icon = null;
                                element2.href = this.$router.options.routes[1].children.find(
                                    (x) => x.name == element2.optCode
                                );
                            });
                        }
                    });
                }
            });

            this.userMenu.sort((x, y) => x.optOrder - y.optOrder);

        },
        logout() {
            localStorage.removeItem('UsrID');
            localStorage.removeItem('token');
            this.$router.push("/micuenta");
        },

        configurateActivity() {

            this.timeToken = setInterval(() => {
                
                if (this.$fun.getUserID() > 0) {
                    
                    let time = localStorage.getItem("TimeTokenCount") - 60000;
                    localStorage.setItem("TimeTokenCount", time);

                    if (localStorage.getItem("TimeTokenCount") <= 60000) {
                        if (localStorage.getItem("token") != null) {

                            // _sAuth.refreshToken({
                            //     usrID: this.$fun.getUserID(),
                            //     xTokenOld: localStorage.getItem("token"),
                            //     xRole: "Permissions.Role.RoleIntern"
                            // }).then((r) => {
                            //     localStorage.setItem("TimeTokenCount", 0);
                            //     localStorage.setItem("TimeTokenCount", localStorage.getItem("TimeToken"));
                            //     localStorage.setItem("token", "");
                            //     localStorage.setItem("token", r.data.data.xToken);

                            // });

                        }
                    }
                }
            }, 3000);
        },

        toggleBodyClass(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        toggleSidebarHover(add, className) {
            const el = document.body;
            this.sidebarActive = !this.sidebarActive;

            this.windowWidth = document.documentElement.clientWidth;

            /* if (!this.usrExtern) { */
            if (this.windowWidth > "992") {
                if (add === "add") {
                    el.classList.add(className);
                } else {
                    el.classList.remove(className);
                }
            }
            /* } */
        },
        getWindowWidth() {
            const el = document.body;

            this.windowWidth = document.documentElement.clientWidth;

            /* if (!this.usrExtern) { */
            if (this.windowWidth < "1350") {
                el.classList.add("closed-sidebar", "closed-sidebar-md");
            } else {
                el.classList.remove("closed-sidebar", "closed-sidebar-md");
            }
            /* } */
        },

        /*   closeSidebar() {
              const el = document.body;
              this.windowWidth = document.documentElement.clientWidth;

              if (this.usrExtern) {
                  el.classList.add("closed-sidebar", "closed-sidebar-md");
              } else {
                  el.classList.remove("closed-sidebar", "closed-sidebar-md");
              }
          }, */

    },
    watch: {
        $route(to, from) {},
    },
    computed: {

    },

    mounted() {
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);

            //Init
            this.getWindowWidth();
            //this.closeSidebar();
        });
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowWidth);
    },
};
</script>
