import Service from "./Service";
const menu = "Account/";

export default {
    login(request) {
        localStorage.clear();
        console.log("menu + login ",menu + "login");
        console.log("request --> ",request);
        // console.log("params --> ",params);
        return Service.post(menu + "login", request , {
            params: {},
        });
    },

    refreshToken(request) {
        return Service.post(menu + "refresh-token", request , {
            params: {},
        });
    },
   
};