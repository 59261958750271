/* window.$ = window.jQuery = require("jquery"); */
window.$ = require("jquery");
window.jQuery = require("jquery");
import push from "push.js";

import Vue from "vue";
import App from "./Core.vue";
import router from "./router";

//
/*
 */
import ArgonDashboard from "./argon-dashboard";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

/* import 'bootstrap/dist/css/bootstrap.css';
 */
import "bootstrap-vue/dist/bootstrap-vue.css";
import moment from "moment";
import VueMoment from "vue-moment";
import VueMask from "vue-the-mask";
import IdleVue from "idle-vue";
import _ from "lodash";
import CKEditor from "ckeditor4-vue";
/* import CKEditor from '@ckeditor/ckeditor5-vue2'; */
import rate from "vue-rate";


import store from "./store/";
import vuetify from "./plugins/vuetify.js";
import fun from "./helpers/fun";

import constapp from "./helpers/const-es";
import base from "./plugins/base";
import axios from "./services/Service";
import "./registerServiceWorker";
import vcNotification from "./components/Utils/vcNotification.vue";
import { showWarningConsole } from "./plugins/warning.js";

const eventsHub = new Vue();
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 600000, // 10 minutos,
    startAtIdle: false,
});

const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

const eventErr = new Vue();
Vue.prototype.$eventErr = eventErr;

Vue.use(_);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(rate);

Vue.prototype.moment = moment;
Vue.prototype.$const = constapp;
Vue.prototype.$fun = fun;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;

function showNotification(error) {
    const ComponenteError = Vue.extend(vcNotification);
    // Montar el componente en un elemento nuevo
    const errorComponent = new ComponenteError({
        propsData: {
            message: error,
        },
    }).$mount();

    // Agregar el componente al DOM en el lugar donde deseas mostrarlo
    document.body.appendChild(errorComponent.$el);
}

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
            config.headers.common["CpyID"] = Number(fun.getCompanyID());
            config.headers.common["UsrID"] = Number(fun.getUserID());
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    async (error) => {
        console.log("ERROR LLAMADA AXIOS-> ", error);
        if (!error.response) {
            fun.alertFull("Error de Conexión", "error");
            return Promise.reject(error);
        }

        // Si la respuesta es de tipo blob
        if (error.response.data instanceof Blob) {
            try {
                const text = await error.response.data.text();
                error.response.data = JSON.parse(text);
            } catch (e) {
                error.response.data = { message: "Error al procesar la respuesta." };
            }
        }

        let errorMessage =
            error.response.data?.errors?.message ??
            error.response.data?.errors ??
            error.response.data?.message ??
            "No se pudo interceptar error.";

        let messageResponse = "[Error]";

        error.response.data = {errors: errorMessage};

        console.log("errorMessagecv",errorMessage);

        switch (error.response.status) {
            case 400:
                messageResponse = errorMessage;
                showNotification(messageResponse);
                fun.alertFull(JSON.stringify(messageResponse), "error");
                break;
            case 401:
                showNotification("Solicitud denegada, Usuario no autenticado.");
                Vue.prototype.$eventBus.$emit("sessionExpired");
                localStorage.clear();
                router.push("/micuenta");
                break;
            case 403:
                showNotification("Solicitud denegada, no tiene permiso para acceder al recurso.");
                fun.alert("Solicitud denegada, no tiene permiso para acceder al recurso.", "warning");
                router.push("/");
                break;
            case 404:
                if (localStorage.getItem("token")) {
                    fun.alert("El servicio solicitado no existe.");
                }
                break;
            case 500:
                if (localStorage.getItem("token")) {
                    fun.alert(errorMessage, "error");
                } else {
                    fun.alert("[Connection Failed]", "error");
                }
                localStorage.setItem("isIdle", false);
                localStorage.clear();
                router.push("/micuenta");
                break;
            case 502:
                if (localStorage.getItem("token")) {
                    fun.alert(errorMessage, "error");
                }
                break;
            default:
                fun.alert("Ocurrió un error inesperado.", "error");
                break;
        }
        return Promise.reject(error);
    }
);

// Load Locales ('en' comes loaded by default)
require("moment/locale/es");

// Choose Locale
moment.locale("es");

Vue.use(VueMoment, { moment });
Vue.directive("focus", {
    inserted: function (el) {
        el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
    },
});

Vue.config.productionTip = false;
Vue.config.silent = true
Vue.config.devtools = false

Vue.config.errorHandler = function (err, vm, info) {
    Vue.prototype.$eventErr.$emit("errorGlobal", info);
};




new Vue({
    el: "#app",
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");


function cargarPagina() {
    showWarningConsole();
}
window.onload = cargarPagina;

// Configuración del Service Worker para recargar en caso de actualización
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("controllerchange", () => {
        window.location.reload();
    });
}
